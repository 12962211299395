import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BallTriangle } from 'react-loader-spinner';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import { SlCalender } from "react-icons/sl";
import appUrl from '../../../appUrl';
import 'react-datepicker/dist/react-datepicker.css';
import { IoIosArrowDown } from "react-icons/io";
import '../../Create/Create.css';
import '../publish.css';

const CreateReport = () => {
  const AppUrl = appUrl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fetchedOption, setFetchedOption] = useState([]);
  const [reportDetails, setReportDetails] = useState({});
  const [isUpdate] = useState(localStorage.getItem("isUpdate"));
  const [isTeam, setIsTeam] = useState(false);

  const schema = Yup.object().shape({
    report_name: Yup.string().required('Report Name is required'),
    participants: Yup.array().min(1, 'Participants selection is required'),
    species: Yup.array().min(1, 'Species selection is required'),
    species_stat: Yup.string().required('Species stats selection is required'),
    species_number: Yup.number().positive('Must be positive').integer('Must be an integer'),
  });

  const handleSubmission = async (values) => {
    setLoading(true);
    const fishingToken = localStorage.getItem('token');
    const tournament_id = localStorage.getItem('tournament');
    const report = localStorage.getItem('report');
    // const method = isUpdate === "true" ? 'PUT' : 'POST';
    // const url = isUpdate === "true" ? `${AppUrl}/api/v1/report/view?${reportDetails._id}` : `${AppUrl}/api/v1/report/create`;
    const url = `${AppUrl}/api/v1/report/create`;

    // Convert "Public" or "Private" to a boolean
    const isPublic = values.public === "Public";

    if (values.species.includes('all')) {
      values.species = fetchedOption.species?.map((spec) => spec.id) || [];
    }
    if (values.tackle_type.includes('all')) {
      values.tackle_type = fetchedOption.fishingStyles?.map((tackle) => tackle.id) || [];
    }
    if (values.special_category.includes('all')) {
      values.special_category = fetchedOption.special_category?.map((cat) => cat._id) || [];
    }

    // console.log()

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${fishingToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...values,
          tournament: tournament_id,
          public: isPublic,
          report_id: report
        }),
      });
      const data = await response.json();
      if (response.ok && data.status === true) {
        localStorage.setItem("report", data.report._id);
        navigate("/user-login/publish/report");
      } else {
        NotificationManager.error(data.message || 'Failed to submit report.');
      }
    } catch (error) {
      NotificationManager.error(error.message || 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getReportDetails = async () => {
      if (isUpdate) {
        const fishingToken = localStorage.getItem('token');
        const reportID = localStorage.getItem("report");
        try {
          const response = await fetch(`${AppUrl}/api/v1/report/view?report_id=${reportID}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${fishingToken}`,
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          if (data.code === 200 || data.status === true) {
            setReportDetails(data.data);
          } else {
            console.error('Failed to fetch report details');
          }
        } catch (error) {
          console.error('Error fetching report details:', error);
        }
      }
    };
    getReportDetails();
  }, [isUpdate]);

  const initialValues = isUpdate === "true"
    ? {
      report_name: reportDetails.report_name || '',
      participants: reportDetails.participants || [],
      range: reportDetails.range || [],
      guided: reportDetails.guided || null,
      tackle_type: reportDetails.tackle_type || [],
      species: reportDetails.species || [],
      species_number: reportDetails.species_number || '',
      species_stat: reportDetails.species_stat || '',
      first_catch: reportDetails.first_catch || false,
      public: reportDetails.public,
      special_category: reportDetails.special_category || [],
      report_id: reportDetails.report_id || "",
      capture_date: reportDetails.capture_date || "",
      multipliers: reportDetails.multipliers || false,
    }
    : {
      report_name: '',
      participants: [],
      range: [],
      guided: null,
      tackle_type: [],
      species: [],
      species_number: '',
      species_stat: '',
      first_catch: false,
      public: true,
      special_category: [],
      report_id: "",
      capture_date: "",
      multipliers: false
    };

  useEffect(() => {
    const getSelectionOptions = async () => {
      const fishingToken = localStorage.getItem('token');
      const tournamentId = localStorage.getItem("tournament");
      try {
        const response = await fetch(`${AppUrl}/api/v1/report/details?tournament_id=${tournamentId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${fishingToken}`,
            'Content-Type': 'application/json',
          }
        });
        const data = await response.json();
        if (data.code === 200 || data.status === true) {
          setFetchedOption(data.data);
        } else {
          console.error('Failed to fetch selection options');
        }
      } catch (error) {
        console.error('Error fetching selection options:', error);
      }
    };
    getSelectionOptions();
  }, [AppUrl]);

  const handleParticipantsChange = (event) => {
    const selectedValue = event.target.value;
    setIsTeam(selectedValue === 'teams');
  };

  const handleTeamSelect = () => {

  }

  return (
    <>
      <NotificationContainer />
      <IoArrowBack onClick={() => { navigate("/user-login/publish/report"); localStorage.removeItem("isUpdate") }} className='back-arrow' />
      <div>
        {loading && (
          <div className='loader-overlay'>
            <BallTriangle color="#00555C" height={80} width={80} />
          </div>
        )}
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmission(values);
            setSubmitting(false);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className='mb-3'>
              <div className='details-field mt-3 mb-3'>
                <label htmlFor='report_name'>Report Name</label>
                <Field
                  type='text'
                  id='report_name'
                  name='report_name'
                  autoComplete='off'
                />
                <div className='error-wrapper mb-3'>
                  <ErrorMessage name='report_name' component='div' className='error' />
                </div>
              </div>

              <div className="details-field mb-3">
                <label htmlFor="participants">Participants</label>
                <Field
                  as="select"
                  name="participants"
                  id="participants"
                  className="form-control"
                  multiple
                  onChange={(event) => {
                    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
                    setFieldValue('participants', selectedOptions);

                    // Set isTeam based on selection
                    if (selectedOptions.includes('teams')) {
                      setIsTeam(true);
                    } else {
                      setIsTeam(false);
                    }
                  }}
                // onChange={(event) => {
                //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
                //   setFieldValue('participants', selectedOptions);
                // }}
                >
                  <option value="">Select Participants</option>
                  <option value="all">All</option>
                  <option value="angler">Angler</option> <option value="guide">Guide</option>
                  <option value="teams" onClick={handleTeamSelect}>Team</option>

                </Field>
                <IoIosArrowDown />
                <ErrorMessage name="participants" component="div" className="error" />
              </div>

              {isTeam && (
                <div className="details-field mb-3">
                  <label htmlFor="guided">Guided</label>
                  <Field as="select" name="guided" id="guided" className="form-control">
                    <option value="">Select Guided</option>
                    <option value="all">All</option>
                    <option value="guided">Guided</option>
                    <option value="unguided">Unguided</option>
                  </Field>
                  <ErrorMessage name="guided" component="div" className="error" />
                </div>
              )}



              <div className='details-half'>


                <div className="details-field mb-3">
                  <label htmlFor="species">Species</label>
                  <Field
                    as="select"
                    name="species"
                    id="species"
                    className="form-control"
                    multiple
                    onChange={(event) => {
                      const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
                      setFieldValue('species', selectedOptions);
                    }}
                  >
                    <option value="all">All</option>
                    {fetchedOption?.species?.map((spec) => (
                      <option key={spec.id} value={spec.id}>
                        {spec.name}
                      </option>
                    ))}
                  </Field>
                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="species" component="div" className="error-message" />
                  </div>
                </div>

                <div className="details-field mb-3">
                  <label htmlFor="range">Range</label>
                  <Field
                    as="select"
                    name="range"
                    id="range"
                    className="form-control"
                    multiple
                    onChange={(event) => {
                      const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
                      setFieldValue('range', selectedOptions);
                    }}
                  >
                    <option value="">Select Range</option>
                    <option value="all">All</option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="junior">Junior</option>
                    <option value="senior">Senior</option>
                    <option value="youth">Youth</option>
                  </Field>
                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="range" component="div" className="error-message" />
                  </div>
                </div>

              </div>


              <div className='details-half'>

                <div className="details-field mb-3">
                  <label htmlFor="tackle_type">Tackle Type</label>
                  <Field
                    as="select"
                    name="tackle_type"
                    id="tackle_type"
                    className="form-control"
                    multiple
                    onChange={(event) => {
                      const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
                      setFieldValue('tackle_type', selectedOptions);
                    }}
                  >
                    <option value="all">All</option>
                    {fetchedOption?.fishingStyles?.map((style) => (
                      <option key={style.id} value={style.id}>
                        {style.fishing_style}
                      </option>
                    ))}
                  </Field>
                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="tackle_type" component="div" className="error-message" />
                  </div>
                </div>

                <div className="details-field mb-3">

                  <label htmlFor="special_category">Special Category</label>
                  <Field
                    as="select"
                    name="special_category"
                    id="special_category"

                    className="form-control"
                    multiple
                    onChange={(event) => {
                      const selectedOptions = Array.from(
                        event.target.selectedOptions,
                        (option) => option.value
                      );
                      // Ensure "all" includes all options
                      const updatedOptions =
                        selectedOptions.includes("all")
                          ? fetchedOption.specialCategory?.map((cat) => cat.id) || []
                          : selectedOptions;
                      setFieldValue("special_category", updatedOptions);
                    }}
                  >
                    <option value="all">All</option>
                    {fetchedOption?.specialCategory?.map((style) => (
                      <option key={style.id} value={style.id}>
                        {style.title}
                      </option>
                    ))}
                  </Field>
                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage
                      name="special_category"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
              </div>

              <div className='details-half'>
                <div className="details-field mb-3">
                  <label htmlFor="species_number">Quantity per Species</label>
                  <Field
                    type="number"
                    name="species_number"
                    className="form-control"
                  />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="species_number" component="div" className="error-message" />
                  </div>
                </div>

                <div className="details-field mb-3">
                  <label htmlFor="species_stat">Species stats to use</label>
                  <Field
                    as="select"
                    name="species_stat"
                    id="species_stat"
                    className="form-control"
                  >
                    <option value="">Select Range</option>
                    <option value="points">Points</option>
                    <option value="weight">Weight</option>
                    <option value="length">Length</option>
                  </Field>
                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="species_stat" component="div" className="error-message" />
                  </div>
                </div>

              </div>

              <div className='details-half'>
                <div className='details-field mb-3'>
                  <label htmlFor='capture_date'>Date of Capture</label>
                  <div className="date-picker-wrapper">
                    <DatePicker
                      selected={values.capture_date}
                      onChange={(date) => {
                        setFieldValue('capture_date', date);
                      }}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="Select date"
                      showYearDropdown
                      maxDate={new Date()}
                      scrollableYearDropdown
                      className="date-picker"
                    />
                    <SlCalender className="calendar-icon" />
                  </div>
                  <div className='error-wrapper mb-3'>
                    <ErrorMessage name='start_date' component='div' className='error' />
                  </div>
                </div>
                <div>

                </div>

                <div className="details-field mb-3">
                  <label htmlFor="public">Report Visibility</label>
                  <Field
                    as="select"
                    name="public"
                    id="public"
                    className="form-control"
                    value={values.public === true ? "Public" : "Private"}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setFieldValue("public", selectedValue === "Public");
                    }}
                  >
                    <option value="Public">Public</option>
                    <option value="Private">Private</option>
                  </Field>
                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="public" component="div" className="error-message" />
                  </div>
                </div>
              </div>
              <div className='create-report-selection mb-3'>

                <div className="create-report-selection mb-3">
                  <div className="report-checkbox-main">
                    <Field
                      type="checkbox"
                      id="multipliers"
                      name="multipliers"
                      onChange={(e) => setFieldValue('multipliers', e.target.checked)}
                      checked={values.multipliers}
                    />
                    <label htmlFor="multipliers">Use Multipliers</label>
                  </div>

                  

                  {/* {reportDetails?.species?.length <= 1 && ( */}
                    <div className="report-checkbox-main">
                      <Field
                        type="checkbox"
                        id="first_catch"
                        name="first_catch"
                        onChange={(e) => setFieldValue('first_catch', e.target.checked)}
                        checked={values.first_catch}
                      />
                      <label htmlFor="first_catch">First Catch on Top for ties</label>
                    </div>
                  {/* )} */}


                  <div className="report-checkbox-main">
                    <Field
                      type="checkbox"
                      id="largest_species"
                      name="largest_species"
                    // onChange={(e) => setFieldValue('largest_species', e.target.checked)}
                    // checked={values.largest_species}
                    />
                    <label htmlFor="largest_species">Use Largest per species Regardless of Tackle type</label>
                  </div>
                </div>
              </div>

              <Button
                type='submit'
                variant="success"
                disabled={loading}
                className='next-create-button mb-5'
              >
                {isUpdate === "true" ? "Update Report" : "Create Report"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateReport;