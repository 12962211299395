import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import 'react-notifications/lib/notifications.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "../src/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { AuthProvider } from './Store/useAuth';
import Sidebar from "./Components/Sidebar/Sidebar";
import RegisteredUsers from "./Pages/Users.js/RegisteredUsers";
import UserName from "./Components/UserName";
import Tournament from "./Pages/Tournament/Tournament";
import TournamentFishes from "./Pages/Fishes/TournamentFishes";
import AssistantSidebar from "./Components/Sidebar/AssistantSidebar";
import ActiveTournament from "./Pages/Assistant/ActiveTournaments";
import UpcomingTournament from "./Pages/Assistant/UpcomingTournament";
import HistoryTournament from "./Pages/Assistant/HistoryTournaments";
import AssistantDashboard from "./Pages/Dashboard/AssistantDashboard";
import AdminLogin from './Login/AdminLogin';
import AssistantLogin from './Login/AssistantLogin';
import CreateTournament from './Pages/CreateTournament/CreateTournament';
import PublishTournament from './Pages/publishTournament/PublishTournament';
import TournamentDetails from './Components/Table/TournamentDetails';
import ViewReport from './Pages/ViewReports/ViewReport';
import "./App.css";
import UserTable from './Components/Table/UserTable';
import ReportFishTable from './Components/Table/ReportFishTable';
import appUrl from './appUrl';

// Custom Route Wrapper for Admin and User Paths
const PrivateRoute = ({ component: Component, role, ...rest }) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const location = useLocation();

  if (!isLoggedIn) {
    if (location.pathname.includes('admin')) {
      return <Navigate to="/admin-login" />;
    } else if (location.pathname.includes('user')) {
      return <Navigate to="/user-login" />;
    }
  }

  if (role === 'admin' && isAdmin) {
    return <Component {...rest} />;
  }

  if (role === 'user' && !isAdmin) {
    return <Component {...rest} />;
  }

  return <Navigate to="/" />;
};

// Main Component for User Pages
const UserLayout = () => {
  const AppUrl = appUrl()
  const [tournamentLength, setTournamentLength] = useState({
    ongoing: "",
    history: "",
    active: ""
  })
  const location = useLocation();
  const isFullWidth = location.pathname === '/user-login/report/user';


  const listUserTournaments = async () => {
    console.log("tournamentLength===0")
    try {
      const fishingToken = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Define API URLs
      const urls = [
        `${AppUrl}/api/v1/user/tournaments/upcoming`,
        `${AppUrl}/api/v1/user/tournaments/history`,
        `${AppUrl}/api/v1/user/tournaments/active`
      ];

      // Create the fetch requests
      const requests = urls.map(url =>
        fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${fishingToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ time_zone: timeZone, user_id: userId }),
        })
      );

      // Execute all fetch requests concurrently
      const responses = await Promise.all(requests);
      console.log(responses, "tournamentLength===1")
      // Parse all responses
      const data = await Promise.all(responses.map(response => response.json()));
      console.log(data[0], data[1], data[2], "tournamentLength===2")
      // Process each response
      if (data[0].code === 200) {
        setTournamentLength(prevState => ({
          ...prevState,
          ongoing: data[0].data.length,
        }));
      }

      if (data[1].code === 200) {
        setTournamentLength(prevState => ({
          ...prevState,
          history: data[1].data.length,
        }));
      }

      if (data[2].code === 200) {
        setTournamentLength(prevState => ({
          ...prevState,
          active: data[2].data.length,
        }));
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };
  console.log(tournamentLength, "tournamentLength")

  useEffect(() => {
    listUserTournaments()
  }, [])

  // Store tournamentLength in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('tournamentLength', JSON.stringify(tournamentLength));
  }, [tournamentLength]);

  return (
    <Row className="m-0">
      {!isFullWidth && (
        <Col className="col_1_open" lg={3} md={3}>
          <AssistantSidebar />
        </Col>
      )}
      <Col className={isFullWidth ? "col-12" : "col_2_open"} lg={isFullWidth ? 12 : 9} md={isFullWidth ? 12 : 9}>
        <div className="bellicon">
          {!isFullWidth && <UserName />}
        </div>
        <div className="pages">
          <Routes>
            <Route path="dashboard" element={<AssistantDashboard />} />
            <Route path="create/*" element={<CreateTournament />} />
            <Route path="publish/*" element={<PublishTournament />} />
            <Route path="active/*" element={<ActiveTournament />} />
            <Route path="upcoming/*" element={<UpcomingTournament />} />
            <Route path="history/*" element={<HistoryTournament />} />
            <Route path="/tournament-table/:id" element={<TournamentDetails />} />
            <Route path="reports" element={<ViewReport />} />
            <Route path="report/user" element={<UserTable />} />
            <Route path="report/fish" element={<ReportFishTable />} />
          </Routes>
        </div>
      </Col>
    </Row>
  );
};

function App() {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  return (
    <Router>
      <div>
        <Routes>
          {/* Admin Login Page */}
          <Route path="/admin-login" element={<AdminLogin />} />

          {/* User Login Page */}
          <Route path="/user-login" element={<AssistantLogin />} />

          {/* Admin Pages */}
          <Route
            path="/admin-login/*"
            element={<PrivateRoute
              component={() => (
                <>
                  <Row className="m-0">
                    <Col className="col_1_open" lg={3} md={3}>
                      <Sidebar />
                    </Col>
                    <Col className="col_2_open" lg={9} md={9}>
                      <div className="bellicon">
                        <UserName />
                      </div>
                      <div className="pages">
                        <Routes>
                          <Route path="dashboard" element={<Dashboard />} />
                          <Route path="users/*" element={<RegisteredUsers />} />
                          <Route path="tournament/*" element={<Tournament />} />
                          <Route path="fish/*" element={<TournamentFishes />} />
                        </Routes>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              role="admin"
            />}
          />

          {/* Redirect to the correct dashboard based on the role */}
          <Route
            path="/"
            element={isLoggedIn ? (isAdmin ? <Navigate to="/admin-login/dashboard" /> : <Navigate to="/user-login/dashboard" />) : <Login />}
          />

          {/* User Pages */}
          <Route
            path="/user-login/*"
            element={<PrivateRoute component={UserLayout} role="user" />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default function WrappedApp() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}
// // Import necessary dependencies
// import React, { useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
// import { Row, Col } from "react-bootstrap";
// import 'react-notifications/lib/notifications.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Login from "../src/Login/Login";
// import Dashboard from "./Pages/Dashboard/Dashboard";
// import { AuthProvider } from './Store/useAuth';
// import Sidebar from "./Components/Sidebar/Sidebar";
// import RegisteredUsers from "./Pages/Users.js/RegisteredUsers"; 
// import UserName from "./Components/UserName";
// import Tournament from "./Pages/Tournament/Tournament";
// import TournamentFishes from "./Pages/Fishes/TournamentFishes";
// import AssistantSidebar from "./Components/Sidebar/AssistantSidebar";
// import ActiveTournament from "./Pages/Assistant/ActiveTournaments";
// import UpcomingTournament from "./Pages/Assistant/UpcomingTournament";
// import HistoryTournament from "./Pages/Assistant/HistoryTournaments";
// import AssistantDashboard from "./Pages/Dashboard/AssistantDashboard";
// import AdminLogin from './Login/AdminLogin';
// import AssistantLogin from './Login/AssistantLogin';
// import CreateTournament from './Pages/CreateTournament/CreateTournament';
// import PublishTournament from './Pages/publishTournament/PublishTournament';
// import TournamentDetails from './Components/Table/TournamentDetails';
// import ViewReport from './Pages/ViewReports/ViewReport';
// import "./App.css";
// import UserTable from './Components/Table/UserTable';

// // Custom Route Wrapper for Admin and User Paths
// const PrivateRoute = ({ component: Component, role, ...rest }) => {
//   const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
//   const isAdmin = localStorage.getItem("isAdmin") === "true";
//   const location = useLocation();

//   console.log("location",location)
//   useEffect(() => {
//     console.log(`Current Path: ${location.pathname}`);
//   }, [location]);

//   // Check if the user is not logged in
//   if (!isLoggedIn) {
//     // Check if the path contains "admin" or "user" and navigate accordingly
//     if (location.pathname.includes('admin')) {
//       return <Navigate to="/admin-login" />;
//     } else if (location.pathname.includes('user')) {
//       return <Navigate to="/user-login" />;
//     }
//   }

//   console.log(role,"role")
//   // Admin-specific logic
//   if (role === 'admin' && isAdmin) {
//     return <Component {...rest} />;
//   }

//   // Assistant-specific logic
//   if (role === 'user' && !isAdmin) {
//     return <Component {...rest} />;
//   }

//   return <Navigate to="/" />; // Fallback if the role doesn't match
// };

// function App() {
//   const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
//   const isAdmin = localStorage.getItem("isAdmin") === "true";

//   return (
//     <Router>
//       <div>
//         <Routes>
//           {/* Admin Login Page */}
//           <Route path="/admin-login" element={<AdminLogin />} />

//           {/* User Login Page */}
//           <Route path="/user-login" element={<AssistantLogin />} />

//           {/* Admin Pages */}
//           <Route
//             path="/admin-login/*"
//             element={<PrivateRoute
//               component={() => (
//                 <>
//                   <Row className='m-0'>
//                     <Col className="col_1_open" lg={3} md={3}>
//                       <Sidebar />
//                     </Col>
//                     <Col className="col_2_open" lg={9} md={9}>
//                       <div className='bellicon'>
//                         <UserName />
//                       </div>
//                       <div className="pages">
//                         <Routes>
//                           <Route path="dashboard" element={<Dashboard />} />
//                           <Route path="users/*" element={<RegisteredUsers />} />
//                           <Route path="tournament/*" element={<Tournament />} />
//                           <Route path="fish/*" element={<TournamentFishes />} />
//                         </Routes>
//                       </div>
//                     </Col>
//                   </Row>
//                 </>
//               )}
//               role="admin"
//             />}
//           />

//           {/* Redirect to the correct dashboard based on the role */}
//           <Route
//             path='/'
//             element={isLoggedIn ? (isAdmin ? <Navigate to="/admin-login/dashboard" /> : <Navigate to="/user-login/dashboard" />) : <Login />}
//           />

//           {/* User Pages */}
//           <Route
//             path="/user-login/*"
//             element={<PrivateRoute
//               component={() => (
//                 <>
//                   <Row className='m-0'>
//                     <Col className="col_1_open" lg={3} md={3}>
//                       <AssistantSidebar />
//                     </Col>
//                     <Col className="col_2_open" lg={9} md={9}>
//                       <div className='bellicon'>
//                         <UserName />
//                       </div>
//                       <div className="pages">
//                         <Routes>
//                           <Route path="dashboard" element={<AssistantDashboard />} />
//                           <Route path="create/*" element={<CreateTournament />} />
//                           <Route path="publish/*" element={<PublishTournament />} />
//                           <Route path="active/*" element={<ActiveTournament />} />
//                           <Route path="upcoming/*" element={<UpcomingTournament />} />
//                           <Route path="history/*" element={<HistoryTournament />} />
//                           <Route path="/tournament-table/:id" element={<TournamentDetails />} />
//                           <Route path="reports/*" element={<ViewReport />} />
//                           <Route path="report/user" element={<UserTable />} />
//                         </Routes>
//                       </div>
//                     </Col>
//                   </Row>
//                 </>
//               )}
//               role="user"
//             />}
//           />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default function WrappedApp() {
//   return (
//     <AuthProvider>
//       <App />
//     </AuthProvider>
//   );
// }
