import { createContext, useContext, useEffect, useState } from "react";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import appUrl from "../appUrl";

export const AuthContext = createContext();

const AppUrl = appUrl();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem("token") || null);
  // const AppUrl = appurl()
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    return storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;
  });
  const authorizationToken = `Bearer ${token}`;

  const [sidebar, setSidebar] = useState(true);
  // const [tournamentLength, setTournamentLength] = useState({
  //   ongoing: "",
  //   history: "",
  //   active: ""
  // })

  const storeTokenInLS = async (serverToken) => {
    localStorage.setItem('token', serverToken);
    console.log(serverToken, "serverToken")
    setToken(serverToken);
    localStorage.setItem('isLoggedIn', true);
    console.log("Token stored in localStorage:", serverToken);
    setIsLoggedIn(true);
    // window.location.reload()
  };
  //  localStorage.setItem("isLoggedIn",isLoggedIn)


  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    setToken("");
  };

  // useEffect(() => {
  //   listUserTournaments()
  // }, [])

  // // Store tournamentLength in localStorage whenever it changes
  // useEffect(() => {
  //   localStorage.setItem('tournamentLength', JSON.stringify(tournamentLength));
  // }, [tournamentLength]);

  return (
    <>
      <AuthContext.Provider value={{ isLoggedIn, storeTokenInLS, handleLogout, authorizationToken, sidebar, setSidebar }}>
        {children}
      </AuthContext.Provider>
      <NotificationContainer />
    </>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return authContext;
};
