import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Modal, ModalBody, ModalHeader, Dropdown, Form, Spinner } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { CFormInput, CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter } from '@coreui/react';
import 'react-notifications/lib/notifications.css';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';
import { IoMdArrowBack } from "react-icons/io";
import appUrl from '../../appUrl';
// import './Table.css'
import './TournamentDetails.css';
import { IoMdTime } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import { SlCalender } from "react-icons/sl";
import { PiClockBold } from "react-icons/pi";
import 'react-datepicker/dist/react-datepicker.css';

const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(`2000-01-01T${timeString}`).toLocaleTimeString('en-US', options);
};

const TournamentDetails = () => {
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    const AppUrl = appUrl()
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [currentItem, setCurrentItem] = useState(null);
    const [selectedType, setSelectedType] = useState([]);
    const [showLinesInTime, setShowLinesInTime] = useState(false);
    const [showLinesOutTime, setShowLinesOutTime] = useState(false);
    const [multiplierEdit, setMultiplierEdit] = useState(false);
    const [isSetupEditing, setIsSetupEditing] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [linesIn, setLinesIn] = useState(details?.lines_in || ''); // Added state for Lines In
    const [linesOut, setLinesOut] = useState(details?.lines_out || '');
    const [buttonLoader, setButtonLoader] = useState(false)

    const [newTackle, setNewTackle] = useState({
        fishing_style: '',
        multipliers: 0,
        points: false,
        length: false,
        weight: false,
    });

    const [speciesFormValue, setSpeciesFormValue] = useState({
        speciesName: '',
        speciesPoints: '',
        min_weight: '',
        min_length: '',
        daily_limit: '',
        tournament_limit: '',
    });
    const [teamFormValue, setTeamFormValue] = useState({
        team_name: '',
        team_photo: ''
    });
    const [participantFormValue, setParticipantFormValue] = useState({
        team_members_name: '',
        team_member_photo: '',
        team_members_email: '',
        team_members_type: [],
        special_category: '',
        team_id: ''
    });

    // Set initial values when the component mounts
    useEffect(() => {
        if (currentItem || details) {
            setSpeciesFormValue({
                speciesName: currentItem?.speciesName || details?.speciesName || '',
                speciesPoints: currentItem?.speciesPoints || details?.speciesPoints || '',
                min_weight: currentItem?.min_weight || details?.min_weight || '',
                min_length: currentItem?.min_length || details?.min_length || '',
                daily_limit: currentItem?.daily_limit || details?.daily_limit || '',
                tournament_limit: currentItem?.tournament_limit || details?.tournament_limit || '',
            });
            setTeamFormValue({
                team_name: currentItem?.team_name || details?.team_name || '',
                team_photo: currentItem?.team_photo || details?.team_photo || ''
            });
            setParticipantFormValue({
                team_members_name: currentItem?.team_members_name || details?.team_members_name || '',
                team_member_photo: currentItem?.team_member_photo || details?.team_member_photo || '',
                team_members_email: currentItem?.team_members_email || details?.team_members_email || '',
                team_members_type: currentItem?.team_members_type || details?.team_members_type || [],
                team_id: currentItem?.team_id || details?.team_id || '',
                special_category: currentItem?.special_category || details?.special_category || ''
            });
            setSelectedType(currentItem?.team_members_type || []);
        }
    }, [currentItem, details]);

    useEffect(() => {
        const edit = localStorage.getItem("isEditing")
        if (edit) {
            setIsEdit(true)
        }
    }, [])

    console.log(isEdit, "isEdit")
    console.log(details, "selectedP")

    useEffect(() => {
        // Re-initialize linesIn and linesOut if currentItem is updated
        setLinesIn(currentItem?.lines_in || '');
        setLinesOut(currentItem?.lines_out || '');
    }, [currentItem]);

    // console.log(details.teams[0].team_name, "team")

    const Details = async () => {
        setLoading(true);
        console.log(id, "Apii")
        try {
            const fishingToken = localStorage.getItem('token');
            const response = await fetch(`${AppUrl}/api/v1/tournament/getTournament?id=${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            if (data.code === 200) {
                console.log("called")
                setDetails(data.data);
                setLoading(false);
            } else {
                console.error('Failed to fetch projects of the selected user');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        Details();
    }, []);

    // Handle form input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSpeciesFormValue((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setTeamFormValue((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
        setParticipantFormValue((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    // Handle form submission for team
    const handleTeams = async () => {
        const values = {
            team_name: teamFormValue.team_name,
            team_photo: teamFormValue.team_photo
        };
        console.log('Form Submitted:', values);
        handleUpdateItem('team', values);  // Assuming handleUpdateItem sends this data to API
    };

    // Handle form submission for participant
    // Validate the form before submission
    const validateForm = () => {
        const errors = [];
        if (!participantFormValue.team_members_name.trim()) {
            errors.push('Participant name is required.');
        }
        if (!participantFormValue.team_members_email.trim()) {
            errors.push('Participant email is required.');
        }
        if (!selectedType.length) {
            errors.push('Select at least one participant type.');
        }
        return errors;
    };

    // Handle form submission
    const handleParticipantSubmit = () => {
        const errors = validateForm();
        if (errors.length > 0) {
            alert(`Please fix the following errors:\n- ${errors.join('\n- ')}`);
            return;
        }
        const values = {
            team_members_name: participantFormValue.team_members_name,
            team_member_photo: participantFormValue.team_member_photo,
            team_members_email: participantFormValue.team_members_email,
            team_members_type: selectedType,
            team_id: participantFormValue.team_id
        };
        console.log('Form Submitted:', values);
        handleUpdateItem('participant', values);  // Assuming handleUpdateItem sends this data to API
    };

    // Handle form submit
    const handleSpeciesSubmit = (e) => {
        e.preventDefault();
        console.log('Form Submitted:', speciesFormValue);
        handleUpdateItem('species', speciesFormValue);  // Assuming handleUpdateItem sends this data to API
    };

    function formatUserRole(teamMembersType) {
        return teamMembersType.map(role => role.replace(/_/g, ' ')).join(', ');
    }

    const handleEditClick = (item) => {
        console.log(item, "item")
        setCurrentItem(item);
        // setSelectedType(item.team_members_type);
        setEditModal(true);
    };

    const handleUpdateItem = async (item, values) => {
        console.log('handleUpdateItem triggered');
        console.log(item, "item-values")
        const fishingToken = localStorage.getItem('token');
        const tournament = localStorage.getItem('tournament');
        const id = localStorage.getItem('id');
        let apiUrl = '';
        let method = 'PUT';

        // Determine the API URL and method based on the item type
        switch (item) {
            case 'details':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}`;
                break;
            case 'species':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/species/${id}`;
                break;
            case 'participant':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/member/${id}`;
                method = 'PATCH';
                break;
            case 'team':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/team/${id}`;
                break;
            case 'setup':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/setUp/${id}`;
                break;
            default:
                NotificationManager.error('Invalid item type');
                return;
        }

        try {
            const response = await fetch(apiUrl, {
                method,
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            const data = await response.json();
            if (data.code === 200) {
                NotificationManager.success(`${item} updated successfully`);
                setEditModal(false);
                localStorage.removeItem("id")
                Details(); // Refresh details after update
                setMultiplierEdit(false);
            } else {
                NotificationManager.error(`Failed to update ${item}`);
            }
        } catch (error) {
            console.error('Error:', error);
            NotificationManager.error(`An error occurred while updating ${item}`);
        }
    };

    const handleOptionToggle = (value, setFieldValue) => {
        let updatedSelectedType = [...selectedType];

        if (updatedSelectedType.includes(value)) {
            // If already selected, remove it
            updatedSelectedType = updatedSelectedType.filter(item => item !== value);
        } else {
            // Handle mutually exclusive options for 'guide' and 'angler'
            if (value === 'guide' && updatedSelectedType.includes('angler')) {
                updatedSelectedType = updatedSelectedType.filter(item => item !== 'angler');
            }
            if (value === 'angler' && updatedSelectedType.includes('guide')) {
                updatedSelectedType = updatedSelectedType.filter(item => item !== 'guide');
            }
            updatedSelectedType.push(value);
        }

        setSelectedType(updatedSelectedType);
        setParticipantFormValue('team_members_type', updatedSelectedType);
    };

    const handleNameSubmit = (e) => {
        e.preventDefault();
        const values = {
            tournament_name: e.target.tournament_name.value,
            start_date: e.target.start_date.value,
            end_date: e.target.end_date.value,
            lines_in: linesIn,  // Use state for lines_in
            lines_out: linesOut,
        };
        console.log('Form Submitted:', values);
        handleUpdateItem('details', values);
    };

    const handleChange = (key, value) => {
        setDetails(prevDetails => ({
            ...prevDetails,
            set_up: {
                ...prevDetails.set_up,
                [key]: value
            }
        }));
    };

    const handleTackleChange = (index, field, value) => {
        const updatedTackles = [...details.set_up.tackle_multiplier];
        updatedTackles[index] = {
            ...updatedTackles[index],
            [field]: value,
        };
        setDetails(prevDetails => ({
            ...prevDetails,
            set_up: {
                ...prevDetails.set_up,
                tackle_multiplier: updatedTackles,
            },
        }));
    };

    const handleSetupUpdateItem = async () => {
        const fishingToken = localStorage.getItem('token');
        const tournament = localStorage.getItem('tournament');
        const apiUrl = `${AppUrl}/api/v1/tournament/setUp/${tournament}`;

        try {
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ set_up: details.set_up }),
            });

            const data = await response.json();
            if (data.code === 200) {
                setMultiplierEdit(false)
                setIsSetupEditing(false);  // Exit editing mode
                setDetails(data.data); // Refresh details after save
                NotificationManager.success('Tournament setup updated successfully');
            } else {
                NotificationManager.error('Failed to update setup');
            }
        } catch (error) {
            console.error('Error:', error);
            NotificationManager.error('An error occurred while updating setup');
        }
    };

    const handlePostItem = async (item, values) => {
        const fishingToken = localStorage.getItem('token');
        const tournament = localStorage.getItem('tournament');
        let apiUrl = '';
        let data = {};
        let method = 'PATCH';

        // Determine API endpoint and payload
        switch (item) {
            case 'details':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}`;
                data = values;
                break;
            case 'species':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/species`;
                data = values;
                break;
            case 'participant':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/member`;
                data = values;
                break;
            case 'team':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/team`;
                data = values;
                break;
            case 'tackle':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/multiplier`;
                data = newTackle; // Ensure newTackle is properly defined in your state
                break;
            default:
                NotificationManager.error('Invalid item type');
                return;
        }

        try {
            const response = await fetch(apiUrl, {
                method,
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();

            if (result.code === 200) {
                NotificationManager.success(`${item.charAt(0).toUpperCase() + item.slice(1)} updated successfully.`);
                setNewTackle()
                setIsAdd(false)
                Details(); // Refresh details after update
                localStorage.removeItem('id'); // Clean up localStorage       
            } else {
                NotificationManager.error(`Failed to update ${item.charAt(0).toUpperCase() + item.slice(1)}.`);
            }
        } catch (error) {
            console.error('Error:', error);
            NotificationManager.error(`An error occurred while updating ${item.charAt(0).toUpperCase() + item.slice(1)}.`);
        }
    };


    const handleDeleteItem = async () => {
        setButtonLoader(true)
        const fishingToken = localStorage.getItem('token');
        const tournament = localStorage.getItem('tournament');
        const item = localStorage.getItem('toEdit');
        const id = localStorage.getItem('id');
        let apiUrl = '';
        let method = 'DELETE';

        // Determine the API URL and method based on the item type
        switch (item) {
            case 'details':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}`;
                break;
            case 'species':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/species/${id}`;
                break;
            case 'participant':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/member/${id}`;
                method = 'PATCH';
                break;
            case 'team':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/team/${id}`;
                break;
            case 'tackle':
                apiUrl = `${AppUrl}/api/v1/tournament/${tournament}/multiplier/${id}`;
                break;
            default:
                NotificationManager.error('Invalid item type');
                return;
        }

        try {
            const response = await fetch(apiUrl, {
                method,
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            if (data.code === 200) {
                NotificationManager.success(`${item} updated successfully`);
                setDeleteModal(false);
                setButtonLoader(false)
                Details(); // Refresh details after update
                localStorage.removeItem("id")
            } else {
                NotificationManager.error(`Failed to update ${item}`);
            }
        } catch (error) {
            console.error('Error:', error);
            NotificationManager.error(`An error occurred while updating ${item}`);
        }
    };

    return (
        <>
            <NotificationContainer />
            {loading && (
                <div className='loader-overlay'>
                    <BallTriangle
                        color="#00555C"
                        height={80}
                        width={80}
                    />
                </div>
            )}


            <div className='details'>
                <div className=" mb-4">
                    <IoMdArrowBack onClick={() => {
                        navigate(`/user-login/publish`);
                        localStorage.removeItem("isEditing");
                        localStorage.removeItem("tournament");
                    }} />
                </div>

                {/* Details Section */}
                <div className='details-card mb-5'>
                    <div className='details-head d-flex'>
                        <h3>{details.tournament_name}</h3>
                        {isEdit && <Button
                            onClick={() => {
                                // localStorage.setItem("id", species._id);
                                // localStorage.setItem("toEdit", "details");
                                // setEditModal(true)
                                handleEditClick(details); localStorage.setItem("toEdit", "details");
                            }
                            } style={{ cursor: 'pointer' }} className='edit-button' >
                            Edit
                        </Button>}
                    </div>
                    <hr />
                    <div>
                        <p><CiCalendarDate />{formatDate(details.start_date)} to {formatDate(details.end_date)}</p>
                    </div>
                    <div>
                        <p><IoMdTime />{formatTime(details.lines_in)} to {formatTime(details.lines_out)}</p>
                    </div>
                </div>

                {/* Setup Section */}
                <div>
                    <div className="details-head title-add">
                        <div className="details-title">Setup</div>
                        <div>
                            {isEdit && (
                                !isSetupEditing ? (
                                    <>
                                        <Button
                                            onClick={() => setIsSetupEditing(true)}
                                            className="edit-button"
                                        >
                                            Edit
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            onClick={handleSetupUpdateItem}
                                            className="edit-button"
                                        >
                                            Done
                                        </Button>
                                        <Button
                                            onClick={() => setIsSetupEditing(false)}
                                            className="delete-button"
                                        >
                                            Cancel
                                        </Button>
                                    </>
                                )
                            )}
                        </div>
                    </div>

                    <div className='mt-3'>
                        <Row className='checkbox-table'>
                            <Col>
                                {/* Sound Horns at Lines in/Lines Out */}
                                <div className='selected'>
                                    Sound Horns at Lines in/Lines Out
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.sound_horns}
                                        onChange={(e) => handleChange('sound_horns', e.target.checked)}
                                        disabled={!isSetupEditing}
                                    />
                                </div>
                                {/* Are images required? */}
                                <div className='selected'>
                                    Are images required?
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.image_required}
                                        onChange={(e) => handleChange('image_required', e.target.checked)}
                                        disabled={!isSetupEditing}
                                    />
                                </div>
                                {/* Is picture Geo location required? */}
                                <div className='selected'>
                                    Is picture Geo location required?
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.geolocation_required}
                                        onChange={(e) => handleChange('geolocation_required', e.target.checked)}
                                        disabled={!isSetupEditing}
                                    />
                                </div>
                                {/* Catch must be verified */}
                                <div className='selected'>
                                    Catch must be verified
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.catch_verified}
                                        onChange={(e) => handleChange('catch_verified', e.target.checked)}
                                        disabled={!isSetupEditing}
                                    />
                                </div>
                                {/* Hold Tournament Results Each Day */}
                                <div className='selected'>
                                    Hold Tournament Results Each Day
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.hold_tournament}
                                        onChange={(e) => handleChange('hold_tournament', e.target.checked)}
                                        disabled={!isSetupEditing}
                                    />
                                </div>
                                {/* Is Angler's age required? */}
                                <div className='selected'>
                                    Is Angler's age required?
                                    <input
                                        type='checkbox'
                                        checked={details?.set_up?.angler_age}
                                        onChange={(e) => handleChange('angler_age', e.target.checked)}
                                        disabled={!isSetupEditing}
                                    />
                                </div>

                                {details?.set_up?.angler_age && (
                                    <>
                                        {/* Junior */}
                                        <div className='age-main'>
                                            <p>Junior</p>
                                            <div className='max-age'>
                                                <input
                                                    type="number"
                                                    value={details?.set_up?.junior || ''}
                                                    onChange={(e) => handleChange('junior', e.target.value)}
                                                    disabled={!isSetupEditing}
                                                />
                                                <p>(max age)</p>
                                            </div>
                                        </div>
                                        {/* Youth */}
                                        <div className='age-main'>
                                            <p>Youth</p>
                                            <div className='max-age'>
                                                <input
                                                    type="number"
                                                    value={details?.set_up?.youth || ''}
                                                    onChange={(e) => handleChange('youth', e.target.value)}
                                                    disabled={!isSetupEditing}
                                                />
                                                <p>(max age)</p>
                                            </div>
                                        </div>
                                        {/* Senior */}
                                        <div className='age-main mb-3'>
                                            <p>Senior</p>
                                            <div className='max-age'>
                                                <input
                                                    type="number"
                                                    value={details?.set_up?.senior || ''}
                                                    onChange={(e) => handleChange('senior', e.target.value)}
                                                    disabled={!isSetupEditing}
                                                />
                                                <p>(min age )</p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* Tackle Type Section */}
                <div>
                    <div className='details-head title-add'>
                        <div className='details-title'>Tackle Type</div>
                        <div>

                            {isEdit && (
                                <td>
                                    {!multiplierEdit ? (
                                        <>
                                            <Button className='add-button'
                                                onClick={() => (
                                                    setCurrentItem("tackle"),
                                                    setIsAdd(true))}>
                                                Add +
                                            </Button>
                                            <Button
                                                onClick={() => setMultiplierEdit(true)}
                                                className='edit-button'
                                            >
                                                Edit
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                onClick={handleSetupUpdateItem}
                                                className='edit-button'
                                            >
                                                Done
                                            </Button>
                                            <Button
                                                onClick={() => setMultiplierEdit(false)}
                                                className='delete-button'
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    )}
                                </td>
                            )}
                        </div>
                    </div>

                    <div>
                        <Table>
                            <thead className='multiplier-head'>
                                <tr>
                                    <th>S.No</th>
                                    <th>Fishing Style</th>
                                    <th>Multipliers</th>
                                    <th>Points</th>
                                    <th>Length</th>
                                    <th>Weight</th>
                                    {isEdit && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody> 
                                {details?.set_up?.tackle_multiplier?.length > 0  ? (
                                    details.set_up.tackle_multiplier.map((tackle, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className='tackle-add'>
                                                <input
                                                    type="text"
                                                    value={tackle.fishing_style || ''}
                                                    onChange={(e) => handleTackleChange(index, 'fishing_style', e.target.value)}
                                                    disabled={!multiplierEdit}
                                                />
                                            </td>
                                            <td className='tackle-add-multiplier'>
                                                <input
                                                    type="number"
                                                    value={tackle.multipliers || ''}
                                                    onChange={(e) => handleTackleChange(index, 'multipliers', e.target.value)}
                                                    disabled={!multiplierEdit}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type='checkbox'
                                                    checked={!!tackle.points}
                                                    onChange={(e) => multiplierEdit && handleTackleChange(index, 'points', e.target.checked)}
                                                    disabled={!multiplierEdit}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type='checkbox'
                                                    checked={!!tackle.length}
                                                    onChange={(e) => multiplierEdit && handleTackleChange(index, 'length', e.target.checked)}
                                                    disabled={!multiplierEdit}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type='checkbox'
                                                    checked={!!tackle.weight}
                                                    onChange={(e) => multiplierEdit && handleTackleChange(index, 'weight', e.target.checked)}
                                                    disabled={!multiplierEdit}
                                                />
                                            </td>
                                           { isEdit ? ( <td>
                                                <Button className='delete-button'
                                                    onClick={() => {
                                                        localStorage.setItem("id", tackle._id);
                                                        localStorage.setItem("toEdit", "tackle");
                                                        setDeleteModal(true);
                                                    }}>Delete</Button>
                                            </td>):""}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7">No data available</td>
                                    </tr>
                                )}
                                {currentItem === "tackle" && isAdd ? (
                                    <tr>
                                        <td></td>
                                        <td className='tackle-add'>
                                            <input
                                                type="text"
                                                value={newTackle.fishing_style}
                                                onChange={(e) => setNewTackle({ ...newTackle, fishing_style: e.target.value })}
                                                placeholder="Fishing Style"
                                            />
                                        </td>
                                        <td className='tackle-add-multiplier'>
                                            <input
                                                type="number"
                                                value={newTackle.multipliers}
                                                onChange={(e) => setNewTackle({ ...newTackle, multipliers: e.target.value })}
                                                placeholder="Multipliers"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={newTackle.points}
                                                onChange={(e) => setNewTackle({ ...newTackle, points: e.target.checked })}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={newTackle.length}
                                                onChange={(e) => setNewTackle({ ...newTackle, length: e.target.checked })}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={newTackle.weight}
                                                onChange={(e) => setNewTackle({ ...newTackle, weight: e.target.checked })}
                                            />
                                        </td>
                                        <td>
                                            <>
                                                <Button
                                                    onClick={() => handlePostItem("tackle")}
                                                    className="edit-button"
                                                >
                                                    Add
                                                </Button>
                                                <Button
                                                    onClick={() => setIsAdd(false)}
                                                    className="delete-button"
                                                >
                                                    Cancel
                                                </Button>
                                            </>
                                        </td>
                                    </tr>
                                ) : ""}

                            </tbody>
                        </Table>
                    </div>
                </div>

                {/* Special Category Section */}
                <div>
                    <div className='details-head title-add'>
                        <div className='details-title'>Special Category</div>
                        <Button className='add-button'>Add +</Button>
                    </div>
                    <div className=''>
                        <Table>
                            <thead className='multiplier-head'>
                                <tr>
                                    <th>S.No</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    {isEdit && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {details.set_up?.special_category && details.set_up.special_category.length > 0 ? (
                                    details.set_up.special_category.map((cat, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{cat.title}</td>
                                            <td style={{ width: '440px' }}>{cat.description}</td>
                                            {isEdit && (
                                                <td>
                                                    <Button
                                                        onClick={() => {
                                                            handleEditClick(cat);
                                                            localStorage.setItem("toEdit", "special"); // Pass species to the edit function
                                                            localStorage.setItem("id", cat._id)
                                                        }}
                                                        className='edit-button'>
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            localStorage.setItem("id", cat._id);
                                                            localStorage.setItem("toEdit", "special");
                                                            setDeleteModal(true);
                                                        }}
                                                        className='delete-button'>
                                                        Delete
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7">-</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>

                {/* Species Section */}
                <div>
                    <div className='details-head title-add'>
                        <div className='details-title'>Species</div>
                        <Button className='add-button'>Add +</Button>
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Species Name</th>
                                <th>Points</th>
                                <th>Min Weight</th>
                                <th>Min Length</th>
                                <th>Daily Limit</th>
                                <th>Tournament Limit</th>
                                {isEdit && <th>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {details.species && details.species.length > 0 ? (
                                details.species.map((species, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{species.speciesName || "-"}</td>
                                        <td>{species.speciesPoints || "-"}</td>
                                        <td>{species.min_weight || "-"}</td>
                                        <td>{species.min_length || "-"}</td>
                                        <td id='limit'>{species.daily_limit || "-"}</td>
                                        <td id='limit'>{species.tournament_limit || "-"}</td>
                                        {isEdit && (
                                            <td>
                                                <Button
                                                    onClick={() => {
                                                        handleEditClick(species);
                                                        localStorage.setItem("toEdit", "species"); // Pass species to the edit function
                                                        localStorage.setItem("id", species._id)
                                                    }}
                                                    className='edit-button'>
                                                    Edit
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        localStorage.setItem("id", species._id);
                                                        localStorage.setItem("toEdit", "species");
                                                        setDeleteModal(true);
                                                    }}
                                                    className='delete-button'>
                                                    Delete
                                                </Button>
                                            </td>
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">No species available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>

                {/* Teams Section */}
                <div>
                    <div className='details-head title-add'>
                        <div className='details-title'>Teams</div>
                        <Button className='add-button'>Add +</Button>
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Team Photo</th>
                                <th>Team Name</th>
                                <th>No. of Participants</th>
                                {isEdit && <th>Action</th>}
                                {/* <th>No. of Guide</th>
                            <th>Angler</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {details.teams && details.teams.length > 0 ? (
                                details.teams.map((team, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td><img src={team.team_photo || "-"} alt='team' /></td>
                                        <td>{team.team_name || '-'}</td>
                                        <td>-</td>
                                        {isEdit &&
                                            (<td>
                                                <Button
                                                    onClick={() => {
                                                        handleEditClick(team);
                                                        localStorage.setItem("toEdit", "team");
                                                        localStorage.setItem("id", team._id);
                                                    }}
                                                    style={{ cursor: 'pointer' }} className='edit-button'>
                                                    Edit
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        localStorage.setItem("id", team._id);
                                                        localStorage.setItem("toEdit", "team");
                                                        setDeleteModal(true)
                                                    }}
                                                    className='delete-button'>
                                                    Delete
                                                </Button>
                                            </td>)
                                        }
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No teams available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>

                {/* Participants Section */}
                <div>
                    <div className='details-head title-add'>
                        <div className='details-title'>Participants</div>
                        <Button className='add-button'>Add +</Button>
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Participant Name</th>
                                <th>Participant Role</th>
                                <th>Team</th>
                                <th>Special Category</th>
                                <th>Email</th>
                                {isEdit && <th>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {details.team_members && details.team_members.length > 0 ? (
                                details.team_members.map((members, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{members.team_members_name || "-"}</td>
                                        <td style={{ width: '180px' }}>{formatUserRole(members.team_members_type) || "-"}</td>
                                        <td>{members?.team_name || "-"}</td>
                                        <td>{members?.title || "-"}</td>
                                        <td style={{ textTransform: "lowercase" }}>{members.team_members_email}</td>
                                        {isEdit &&
                                            (<td>
                                                <Button
                                                    onClick={() => {
                                                        handleEditClick(members);
                                                        localStorage.setItem("toEdit", "participant");
                                                        localStorage.setItem("id", members._id);
                                                    }}
                                                    style={{ cursor: 'pointer' }} className='edit-button' >
                                                    Edit

                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        localStorage.setItem("id", members._id);
                                                        localStorage.setItem("toEdit", "participant");
                                                        setDeleteModal(true)
                                                    }}
                                                    className='delete-button'>
                                                    Delete
                                                </Button>
                                            </td>)
                                        }
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No participants available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>


                <div className='details-head title-add'>
                    <div className='details-title'>Tournament Rules</div>
                    <Button className='edit-button'>Edit</Button>
                </div>
                <div className='rules'><li>{details.tournament_rules}</li></div>
            </div >

            {deleteModal && (
                <Modal
                    show={deleteModal}
                    onHide={() => setDeleteModal(false)}
                    centered
                    backdrop="static"
                    className="submission-modal"
                >
                    {(() => {
                        const item = localStorage.getItem("toEdit");
                        return (
                            <>
                                <ModalHeader className="publish-title">
                                    Are you sure you want to delete this {item}?
                                </ModalHeader>
                                <ModalBody>
                                    <div className="d-flex justify-content-center">
                                        <Button
                                            className="publish-later"
                                            onClick={() => {
                                                setDeleteModal(false);
                                                localStorage.removeItem("id");
                                                localStorage.removeItem("toEdit");
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="publish-now"
                                            style={{ backgroundColor: "#d91818", borderColor: "#d91818" }}
                                            onClick={() => (handleDeleteItem())}
                                        >
                                            {buttonLoader ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className='delete-spinner'
                                                />
                                            ) : (
                                                <h6>Delete</h6>
                                            )}
                                        </Button>
                                    </div>
                                </ModalBody>
                            </>
                        );
                    })()}
                </Modal>
            )
            }

            {
                editModal &&
                <Modal
                    show={editModal}
                    onHide={() => setEditModal(false)}
                    centered
                    backdrop="static"
                >
                    <ModalHeader closeButton></ModalHeader>
                    {(() => {
                        const item = localStorage.getItem("toEdit");
                        return (
                            <>
                                {item === "details" &&
                                    // <Modal show={editModal} onHide={() => setEditModal(false)} centered backdrop="static">
                                    // <ModalHeader closeButton></ModalHeader>
                                    <ModalBody>
                                        <form onSubmit={handleNameSubmit}>
                                            <div className="details-field mb-3">
                                                <label htmlFor="tournament_name">Tournament Name</label>
                                                <input id="tournament_name" name="tournament_name" type="text" defaultValue={currentItem?.tournament_name || details.tournament_name} />
                                            </div>

                                            <div className="details-half">
                                                <div className="details-field mb-3">
                                                    <label htmlFor="start_date">Start Date</label>
                                                    <input id="start_date" name="start_date" type="date" defaultValue={currentItem?.start_date || details.start_date} />
                                                </div>
                                                <div className="details-field mb-3">
                                                    <label htmlFor="end_date">End Date</label>
                                                    <input id="end_date" name="end_date" type="date" defaultValue={currentItem?.end_date || details.end_date} />
                                                </div>
                                            </div>

                                            <div className="details-half">
                                                <div className="details-field mb-3">
                                                    <label htmlFor="lines_in">Lines In</label>
                                                    <input id="lines_in" name="lines_in" type="text" value={linesIn} readOnly onClick={() => setShowLinesInTime(true)} />
                                                </div>
                                                <div className="details-field mb-3">
                                                    <label htmlFor="lines_out">Lines Out</label>
                                                    <input id="lines_out" name="lines_out" type="text" defaultValue={linesOut} readOnly onClick={() => setShowLinesOutTime(true)} />
                                                </div>
                                            </div>

                                            <button type="submit" className='next-create-button'>Update</button>

                                            {/* Modals for Lines In and Out */}
                                            <CModal visible={showLinesInTime} backdrop="static" onClose={() => setShowLinesInTime(false)}>
                                                <CModalHeader><CModalTitle>Select Lines In Time</CModalTitle></CModalHeader>
                                                <CModalBody>
                                                    <CFormInput type="time" value={linesIn} onChange={(e) => setLinesIn(e.target.value)} />
                                                    <button onClick={() => setShowLinesInTime(false)} className='next-create-button'>Update</button>
                                                </CModalBody>
                                            </CModal>

                                            <CModal visible={showLinesOutTime} onClose={() => setShowLinesOutTime(false)} backdrop="static">
                                                <CModalHeader><CModalTitle>Select Lines Out Time</CModalTitle></CModalHeader>
                                                <CModalBody>
                                                    <CFormInput type="time" value={linesOut} onChange={(e) => setLinesOut(e.target.value)} />
                                                    <button onClick={() => setShowLinesOutTime(false)} className='next-create-button'>Update</button>
                                                </CModalBody>
                                            </CModal>
                                        </form>
                                    </ModalBody>
                                    // </Modal>
                                }

                                {item === "species" && <ModalBody>
                                    <form onSubmit={handleSpeciesSubmit} className="mb-3">
                                        <div className='species-field'>
                                            <div className="species-name mb-3">
                                                <label htmlFor="speciesName">Species Name<span style={{ color: 'red' }}>*</span></label>
                                                <input
                                                    type="text"
                                                    id="speciesName"
                                                    name="speciesName"
                                                    value={speciesFormValue.speciesName}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                            <div className="species-points mb-3">
                                                <label htmlFor="speciesPoints">Points<span style={{ color: 'red' }}>*</span></label>
                                                <input
                                                    type="number"
                                                    id="speciesPoints"
                                                    name="speciesPoints"
                                                    value={speciesFormValue.speciesPoints}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className='details-half'>
                                            <div className="details-field mb-3">
                                                <label htmlFor="min_weight">Min Weight</label>
                                                <input
                                                    type="number"
                                                    id="min_weight"
                                                    name="min_weight"
                                                    value={speciesFormValue.min_weight}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="details-field mb-3">
                                                <label htmlFor="min_length">Min Length</label>
                                                <input
                                                    type="number"
                                                    id="min_length"
                                                    name="min_length"
                                                    value={speciesFormValue.min_length}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className='details-half'>
                                            <div className="details-field mb-3">
                                                <label htmlFor="daily_limit">Daily Limit</label>
                                                <input
                                                    type="number"
                                                    id="daily_limit"
                                                    name="daily_limit"
                                                    value={speciesFormValue.daily_limit}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="details-field mb-3">
                                                <label htmlFor="tournament_limit">Tournament Limit</label>
                                                <input
                                                    type="number"
                                                    id="tournament_limit"
                                                    name="tournament_limit"
                                                    value={speciesFormValue.tournament_limit}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className='text-center'>
                                            <Button type="submit" className="next-create-button">Update</Button>
                                        </div>
                                    </form>

                                </ModalBody>}


                                {item === "team" && <ModalBody>
                                    <div className="details-field mb-3">
                                        <label htmlFor="team_name">
                                            Add Team Name<span>(50 characters)</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="team_name"
                                            name="team_name"
                                            value={teamFormValue.team_name}
                                            onChange={handleInputChange}
                                            maxLength="50"
                                            autoComplete="off"
                                            className="form-control"
                                        />
                                    </div>
                                    {/* <div className="details-field mb-3">
                        <label htmlFor="team_photo">Team Photo</label>
                        <input
                            type="text"
                            id="team_photo"
                            name="team_photo"
                            value={teamFormValue.team_photo}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                    </div> */}
                                    <Button type="button" className="next-create-button" onClick={handleTeams}>
                                        Update
                                    </Button>
                                </ModalBody>}


                                {item === "participant" && <ModalBody>
                                    <div className="details-field mb-3">
                                        <label htmlFor="team_members_name">
                                            Participant Name <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="team_members_name"
                                            name="team_members_name"
                                            value={participantFormValue.team_members_name}
                                            onChange={handleInputChange}
                                            maxLength="50"
                                            autoComplete="off"
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="details-field mb-3">
                                        <label htmlFor="team_members_email">
                                            E-mail ID <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            id="team_members_email"
                                            name="team_members_email"
                                            value={participantFormValue.team_members_email}
                                            onChange={handleInputChange}
                                            autoComplete="off"
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="details-field mb-3">
                                        <label htmlFor="team_id">Select Team</label>
                                        <select
                                            id="team_id"
                                            name="team_id"
                                            value={participantFormValue.team_id}
                                            onChange={handleInputChange}
                                            className="form-control"
                                        >
                                            <option value="">Select a team</option>
                                            {details?.teams.map((team) => (
                                                <option key={team._id} value={team._id}>
                                                    {team.team_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="details-field mb-3">
                                        <label htmlFor="special_category">Select Special Category</label>
                                        <select
                                            id="special_category"
                                            name="special_category"
                                            value={participantFormValue.special_category}
                                            onChange={handleInputChange}
                                            className="form-control"
                                        >
                                            <option value="">Select</option>
                                            {details?.set_up?.special_category.map((cat) => (
                                                <option key={cat._id} value={cat._id}>
                                                    {cat.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* Participant Type Field */}
                                    <div className="details-field mb-3">
                                        <label htmlFor="team_members_type">
                                            Participant Type <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <Dropdown className="select-type-dropdown" style={{ width: '100%' }}>
                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ width: '100%' }}>
                                                {selectedType.length > 0 ? selectedType.join(', ') : 'Select Participant Type'}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu style={{ width: '100%' }}>
                                                {/* 'guide' and 'angler' are mutually exclusive */}
                                                <Dropdown.Item as="div">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Guide"
                                                        checked={selectedType.includes('guide')}
                                                        onChange={() => handleOptionToggle('guide', setParticipantFormValue)}
                                                    />
                                                </Dropdown.Item>
                                                <Dropdown.Item as="div">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Angler"
                                                        checked={selectedType.includes('angler')}
                                                        onChange={() => handleOptionToggle('angler', setParticipantFormValue)}
                                                    />
                                                </Dropdown.Item>
                                                <Dropdown.Item as="div">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Tournament Assistant"
                                                        checked={selectedType.includes('tournament_assistant')}
                                                        onChange={() => handleOptionToggle('tournament_assistant', setParticipantFormValue)}
                                                    />
                                                </Dropdown.Item>
                                                <Dropdown.Item as="div">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Spectator"
                                                        checked={selectedType.includes('spectator')}
                                                        onChange={() => handleOptionToggle('spectator', setParticipantFormValue)}
                                                    />
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                    <Button className="next-create-button" type="button" onClick={handleParticipantSubmit}>
                                        Update
                                    </Button>
                                </ModalBody>}
                            </>
                        );
                    })()}
                </Modal>
            }
        </>
    )
};

export default TournamentDetails;