import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { Card } from 'react-bootstrap';
import appUrl from '../../appUrl';
import { useNavigate } from 'react-router-dom';

const AssistantDashboard = () => {
  const AppUrl = appUrl();
  const navigate = useNavigate();
  const [count, setCount] = useState(0);

  // const [cardsData, setCardsData] = useState([
  //   {
  //     id: 1,
  //     text: 'Tournaments',
  //     bgColor: '#00555C',
  //     color:"white",
  //     // img: '/images/reward.png',
  //     count: ''
  //   },
  //   // {
  //   //   id: 2,
  //   //   text: 'Tournaments',
  //   //   bgColor: '#E0EFF6',
  //   //   color:"#253f42",
  //   //   // img: '/images/refund.png',
  //   //   count: ''
  //   // },
  // ]);


  const [tournamentLength, setTournamentLength] = useState({
    ongoing: 0,
    history: 0,
    active: 0,
  });

  useEffect(() => {
    const storedTournamentLength = JSON.parse(localStorage.getItem('tournamentLength'));
    if (storedTournamentLength) {
      setTournamentLength(storedTournamentLength);
    }
  }, []);

  const cardsData = [
    {
      id: 1,
      title: 'Active Tournaments',
      backgroundColor: "#00848F",
      color:"white",
      count: tournamentLength.active,
      onClick: () => {
        navigate('/user-login/active');
        sessionStorage.setItem('selectedItem', 'active');
      },
    },
    {
      id: 2,
      title: 'Ongoing Tournaments',
      count: tournamentLength.ongoing,
      backgroundColor: "#5C0055",
      color:"white",
      onClick: () => {
        navigate('/user-login/upcoming');
        sessionStorage.setItem('selectedItem', 'upcoming');
      },
    },
    {
      id: 3,
      title: 'History Tournaments',
      count: tournamentLength.history,
      backgroundColor:"#555C00",
      color:"white",
      onClick: () => {
        navigate('/user-login/history');
        sessionStorage.setItem('selectedItem', 'history');
      },
    },
  ];

  useEffect(() => {
    listData();
  }, []);

  const listData = async () => {
    try {
      const fishingToken = localStorage.getItem('token');

      const response = await fetch(`${AppUrl}/api/v1/user/tournament/count`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${fishingToken}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log(data, "datatata");

      if (data.code === 200) {
        setCount(data?.data?.tournaments.count);
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className='renovation'>
         
            <div className="d-flex mb-5" style={{ cursor: "pointer" }} onClick={() => {
              navigate("/user-login/active");
              sessionStorage.setItem("selectedItem", "active")
            }
            }>
              <Card
                style={{ width: '18rem', margin: '6px', backgroundColor: "#00555C", color: "white" }}
                className='inspection-card'
              >
                <Card.Body>
                  {/* <Card.Img src={card.img} className='card-img' /> */}
                  <Card.Text>Published</Card.Text>
                  <Card.Title>{count}</Card.Title>
                </Card.Body>
              </Card>
            </div>


          <div className='inspection cards'>
          {/* <div className='inspection-row'> */}
            {cardsData.map((card) => (
              <Card
                key={card.id}
                style={{
                  width: '18rem',
                  margin: '6px',
                  backgroundColor: card?.backgroundColor,
                  color: card?.color,
                  cursor: 'pointer'
                }}
                onClick={card.onClick}
              >
                <Card.Body>
                  <Card.Text style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{card?.title}</Card.Text>
                  <Card.Title style={{ fontSize: '3rem' }}>{card?.count}</Card.Title>
                </Card.Body>
              </Card>))}
          </div>


        {/* </div> */}
      </div>
    </>
  );
};

export default AssistantDashboard;
