// src/actions.js
export const SET_UP = 'SET_UP';
export const SET_SPECIES = 'SET_SPECIES';
export const SET_MEMBERS = 'SET_MEMBERS';
export const SET_RULES = 'SET_RULES';
export const SUBMIT_TOURNAMENT = 'SUBMIT_TOURNAMENT';
export const SUBMIT_TOURNAMENT_SUCCESS = 'SUBMIT_TOURNAMENT_SUCCESS';
export const SUBMIT_TOURNAMENT_FAILURE = 'SUBMIT_TOURNAMENT_FAILURE';
export const CLEAR_STATE = 'CLEAR_STATE';


// Action Creators
// export const setUp = (setup) => ({ type: SET_UP, payload: setup });
export const setUp = (setup) => {
    console.log("Dispatching setup action with special_category:", setup.special_category); // Log here to verify
    return { type: SET_UP, payload: setup };
  };
  
export const setSpecies = (species) => ({ type: SET_SPECIES, payload: species });
export const setMembers = (members) => ({ type: SET_MEMBERS, payload: members });
export const setRules = (rules) => ({ type: SET_RULES, payload: rules });

// Thunk Action for Submitting Tournament
export const submitTournament = () => {
    return async (dispatch, getState) => {
        dispatch({ type: SUBMIT_TOURNAMENT });
        const state = getState();
        const { setup, species, members, rules } = state;

        const payload = {
            // set_up: setup,
            set_up: setup,
            species: species,
            team_members: members,
            tournament_rules: rules || '',
            // special_category: setup.special_category || [] 
            special_category: []  
        };

        try {
            const fishingToken = localStorage.getItem("token");
            const tournament_id = localStorage.getItem("tournament");

            const response = await fetch(`https://fishing-tournament.netscapelabs.com/api/v1/tournament/create?tournament_id=${tournament_id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            console.log("Response status:", response.status);
            const data = await response.json();
            console.log("Response data:", data);

            if (response.ok && data.code === 201) { 
                console.log("submitedtour");
                dispatch({ type: SUBMIT_TOURNAMENT_SUCCESS, payload: data });
                // dispatch({ type: CLEAR_STATE });
            } else {
                dispatch({ type: SUBMIT_TOURNAMENT_FAILURE, payload: data.message || 'Failed to submit tournament' });
                alert(`Error: ${data.message || 'Failed to submit tournament'}`);
            }
        } catch (error) {
            console.error("Submission error:", error);
            dispatch({ type: SUBMIT_TOURNAMENT_FAILURE, payload: error.message });
            alert(`Error: ${error.message}`);
        }
    };
};




