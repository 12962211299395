// src/Components/Create/SetUp.jsx
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { FaPlus, FaTimes } from "react-icons/fa";
import 'react-toggle/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setUp } from '../../actions';
import { useNavigate } from 'react-router-dom';
import './Create.css'; // Ensure the path is correct
import { NotificationManager, NotificationContainer } from "react-notifications";
import AppUrl from '../../appUrl';

const SetUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appUrl = AppUrl()

  // Retrieve existing setup data from Redux store
  const existingSetup = useSelector((state) => state.setup);
  // const [specialCategory, setSpecialCategory] = useState(JSON.parse(localStorage.getItem('specialCategory')) || []);
    const [specialCategory, setSpecialCategory] = useState({
      title:"",
      description:""
    });
    const[specialCategoryList,setSpecialCategoryList] = useState([])

  // Initialize setup state with existing data or default values
  const [setup, setSetup] = useState({
    sound_horns: existingSetup.sound_horns || false,
    image_required: existingSetup.image_required || false,
    geolocation_required: existingSetup.geolocation_required || false,
    catch_verified: existingSetup.catch_verified || false,
    hold_tournament: existingSetup.hold_tournament || false,
    result_time: existingSetup.result_time || false,
    result_time_value: existingSetup.result_time_value || '',
    tackle_multiplier: existingSetup.tackle_multiplier || [], // Initialize as empty array
    angler_age: existingSetup?.angler_age || false,
    junior: existingSetup?.junior || '',
    youth: existingSetup?.youth || '',
    senior: existingSetup?.senior || '',
    // special_category: specialCategory || []
    special_category: []
  });

  // useEffect(() => {
  //   setSetup(prevSetup => ({
  //     ...prevSetup,
  //     special_category: specialCategory,
  //   }));
  // }, [specialCategory]);  // Runs when specialCategory changes
  
  const [isCategory, setIsCategory] = useState(JSON.parse(localStorage.getItem('isCategory')) || false);
  const [currentInput, setCurrentInput] = useState({ title: '', description: '' }); // Temporary input state
  const [isEditing, setIsEditing] = useState(null); // Tracks the index being edited
  const [editingIndex, setEditingIndex] = useState(null); // Add this to your component state
  const [multiplierEdit,setMultiplierEdit] = useState(false)

  console.log(specialCategory, "specialCategory")

  // Handler to update numeric fields (junior, youth, senior)
  const handleNumericChange = (name, value) => {
    // Allow only numbers
    const numericValue = value.replace(/\D/g, '');
    setSetup(prevSetup => ({ ...prevSetup, [name]: numericValue }));
  };

  // Handler to update boolean fields in setup
  const handleToggleChange = (name, value) => {
    if (name === 'tackle_multiplier') {
      if (value) {
        // If toggled on and no multipliers exist, add an initial empty multiplier
        setSetup(prevSetup => ({
          ...prevSetup,
          tackle_multiplier: prevSetup.tackle_multiplier.length > 0 ? prevSetup.tackle_multiplier : [{
            fishing_style: "",
            multipliers: "",
            points: false,
            length: false,
            weight: false
          }]
        }));
      } else {
        // If toggled off, clear all multipliers
        setSetup(prevSetup => ({ ...prevSetup, tackle_multiplier: [] }));
      }
    } else {
      setSetup(prevSetup => ({ ...prevSetup, [name]: value }));
    }
  };

  // Handler to add a new multiplier row
  const handleAddRow = () => {

    setSetup(prevSetup => ({
      ...prevSetup,
      tackle_multiplier: [
        ...prevSetup.tackle_multiplier,
        {
          fishing_style: "",
          multipliers: "",
          points: false,
          length: false,
          weight: false
        }
      ]
    }));
  };

  // Handler to delete a multiplier row by index
  const handleDeleteRow = (index) => {
    setSetup(prevSetup => ({
      ...prevSetup,
      tackle_multiplier: prevSetup.tackle_multiplier.filter((_, i) => i !== index)
    }));
  };

  // Delete a row from the specialCategory array
  // const handleDeleteCategoryRow = (index) => {
  //   const updatedCategories = specialCategory.filter((_, idx) => idx !== index);
  //   setSpecialCategory(updatedCategories);
  // };

  // Start editing a row
  const handleEditCategoryRow = (index) => {
    setIsEditing(index); // Set the row being edited
  };

  // Save changes for the row being edited
  const handleSaveEditedRow = (index) => {
    if (
      specialCategory[index].title.trim() &&
      specialCategory[index].description.trim()
    ) {
      setIsEditing(null); // Exit edit mode
    } else {
      NotificationManager.error('Please ensure both Title and Description are filled before saving.');
    }
  };

  // Handler to update fields within a multiplier row
  const handleMultiplierChange = (index, field, value) => {
    setSetup(prevSetup => {
      const updatedMultiplier = [...prevSetup.tackle_multiplier];
      updatedMultiplier[index][field] = value;
      return { ...prevSetup, tackle_multiplier: updatedMultiplier };
    });
  };

  // Handler for the Next button
  const handleSetupNext = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Log the setup data
    console.log("Dispatching Setup Data:", setup);
    // Dispatch the setup data to Redux store
    // Ensure special_category is part of setup
    const setupData = {
      ...setup,
      special_category: setup.special_category || []  // Include special_category
    };

    // Dispatch the setup data to Redux store
    dispatch(setUp(setupData));
    // handleCategorySubmit();

    // Update the current page in localStorage
    localStorage.setItem("page", "/user-login/create/species");

    // Navigate to the next page
    navigate('/user-login/create/species');
  };

  // Save changes made during editing
  const handleSaveEdit = () => {
    if (currentInput.title.trim() && currentInput.description.trim()) {
      const updatedCategories = [...specialCategory];
      updatedCategories[editingIndex] = currentInput;
      setSpecialCategory(updatedCategories);
      setEditingIndex(null); // Exit editing mode
      setCurrentInput({ title: '', description: '' }); // Reset the temporary input state
    } else {
      alert('Please fill in both Title and Description before saving.');
    }
  };

  const tournamentId = localStorage.getItem("tournament");
  const fishingToken = localStorage.getItem("token");
  const headers = {
    authorization: `Bearer ${fishingToken}`,
    'Content-Type': 'application/json',
  };

const handleCategorySubmit = async () => {
    try {
        const tournamentId = localStorage.getItem("tournament");
        const fishingToken = localStorage.getItem("token");
        const response = await fetch(`${appUrl}/api/v1/tournament/category/create?tournament_id=${tournamentId}`, {
            method: 'POST',
            headers: {
                "authorization": `Bearer ${fishingToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...specialCategory })
        });

        const data = await response.json();
        if (data.status === true) {
          setSpecialCategory({ title: "", description: "" }); 
            console.log(data.data, "categorydata");
            getCategoryList();
        }
    } catch (error) {
        console.error(error.message);
    }
};

const handleEditCategorySubmit = async (categoryId) => {
    try {
        const tournamentId = localStorage.getItem("tournament");
        const fishingToken = localStorage.getItem("token");
        const response = await fetch(`${appUrl}/api/v1/tournament/category?tournament_id=${tournamentId}&category_id=${categoryId}`, {
            method: 'PATCH',
            headers: {
                "authorization": `Bearer ${fishingToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...specialCategoryList.find(cat => cat._id === categoryId) })
        });

        const data = await response.json();
        if (data.status === true) {
            console.log("Category updated successfully");
            setIsEditing(null);
            // setSpecialCategory({})
            getCategoryList();
        }
    } catch (error) {
        console.error(error.message);
    }
};

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSpecialCategory(prevValues => ({
        ...prevValues,
        [name]: value,
    }));
};

const handleEditInputChange = (index, field, value) => {
    const updatedCategories = [...specialCategoryList];
    updatedCategories[index][field] = value;
    setSpecialCategoryList(updatedCategories);
};

const getCategoryList = async () => {
    try {
        const tournamentId = localStorage.getItem("tournament");
        const fishingToken = localStorage.getItem("token");
        const response = await fetch(`${appUrl}/api/v1/tournament/category?tournament_id=${tournamentId}`, {
            method: 'GET',
            headers: {
                "authorization": `Bearer ${fishingToken}`,
                'Content-Type': 'application/json'
            },
        });

        const data = await response.json();
        if (data.status === true) {
            setSpecialCategoryList(data.data);
        }
    } catch (error) {
        console.error(error.message);
    }
};

const handleDeleteCategoryRow = async (categoryId) => {
  try {
      const tournamentId = localStorage.getItem("tournament");
      const fishingToken = localStorage.getItem("token");
      const response = await fetch(`${appUrl}/api/v1/tournament/category?tournament_id=${tournamentId}&category_id=${categoryId}`, {
          method: 'DELETE',
          headers: {
              "authorization": `Bearer ${fishingToken}`,
              'Content-Type': 'application/json'
          },
      });

      const data = await response.json();
      if (data.status === true) {
          console.log("Category deleted successfully");
          // Update the list locally to avoid re-fetching
          setSpecialCategoryList(prevList => prevList.filter(category => category._id !== categoryId));
      }
  } catch (error) {
      console.error(error.message);
  }
};


useEffect(() => {
    getCategoryList();
}, []);

  return (
    <>
    <NotificationContainer/>

      <div className='setup-main'>

        <h3>Setup</h3>

        <form onSubmit={handleSetupNext}>

          <div className='setup-half'>
            <p>Sound Horns at Lines In/Lines Out</p>
            <Toggle
              checked={setup.sound_horns}
              onChange={(e) => handleToggleChange('sound_horns', e.target.checked)}
            />
          </div>

          <div className='setup-half'>
            <p>Are images required?</p>
            <Toggle
              checked={setup.image_required}
              onChange={(e) => handleToggleChange('image_required', e.target.checked)}
            />
          </div>

          <div className='setup-half'>
            <p>Is picture Geo location required?</p>
            <Toggle
              checked={setup.geolocation_required}
              onChange={(e) => handleToggleChange('geolocation_required', e.target.checked)}
            />
          </div>

          <div className='setup-half'>
            <p>Catch must be verified</p>
            <Toggle
              checked={setup.catch_verified}
              onChange={(e) => handleToggleChange('catch_verified', e.target.checked)}
            />
          </div>

          <div className='setup-half'>
            <p>Hold tournament Results Each day</p>
            <Toggle
              checked={setup.result_time}
              onChange={(e) => handleToggleChange('result_time', e.target.checked)}
            />
          </div>

          {setup.result_time && (
            <div className='setup-half'>
              <p className='result-time'>Select result time</p>
              {/* Replace with your preferred time picker component */}
              <input
                type='time'
                value={setup.result_time_value}
                onChange={(e) => setSetup(prev => ({ ...prev, result_time_value: e.target.value }))}
                required
              />
            </div>
          )}

          <div className='setup-half'>
            <p>Are there tackle multipliers?</p>
            <Toggle
              checked={setup.tackle_multiplier.length > 0}
              onChange={(e) => handleToggleChange('tackle_multiplier', e.target.checked)}
            />
          </div>

          {setup.tackle_multiplier.length > 0 && (
            <div className='multipliers mb-3'>
              <table>
                <thead className='multiplier-head'>
                  <tr>
                    <th>Fishing Style</th>
                    <th>Multipliers</th>
                    <th>Points</th>
                    <th>Length</th>
                    <th>Weight</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {setup.tackle_multiplier.map((mult, index) => (
                    <tr className='mb-3' key={index}>
                      <td>
                        <input
                          type='text'
                          value={mult.fishing_style}
                          onChange={(e) => handleMultiplierChange(index, 'fishing_style', e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <input
                          type='number'
                          value={mult.multipliers}
                          onChange={(e) => handleMultiplierChange(index, 'multipliers', e.target.value)}
                          required
                          min="0"
                          step="0.01"
                        />
                      </td>
                      <td>
                        <input
                          type='checkbox'
                          className='setup-checkbox'
                          checked={mult.points}
                          onChange={(e) => handleMultiplierChange(index, 'points', e.target.checked)}
                        />
                      </td>
                      <td>
                        <input
                          type='checkbox'
                          className='setup-checkbox'
                          checked={mult.length}
                          onChange={(e) => handleMultiplierChange(index, 'length', e.target.checked)}
                        />
                      </td>
                      <td>
                        <input
                          type='checkbox'
                          className='setup-checkbox'
                          checked={mult.weight}
                          onChange={(e) => handleMultiplierChange(index, 'weight', e.target.checked)}
                        />
                      </td>
                      <td>
                        <FaTimes onClick={() => handleDeleteRow(index)} style={{ cursor: 'pointer' }} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button type='button' onClick={handleAddRow} className='add-row-button'>
                <FaPlus /> Add
              </Button>
            </div>
          )}
          <div className='setup-half'>

            <p> Is Angler's age required? </p>

            <Toggle
              checked={setup.angler_age}
              onChange={(e) => handleToggleChange('angler_age', e.target.checked)}
            />
          </div>


          {setup.angler_age && (
            <>
              <div className='age-main' >
                <p>Junior </p>

                <div className='max-age'>
                  <input
                    type="number"
                    value={setup.junior}
                    onChange={(e) => handleNumericChange('junior', e.target.value)}
                  />
                  <p>(max age)</p>
                </div>
              </div>

              <div className='age-main' >
                <p>Youth</p>

                <div className='max-age'>
                  <input
                    type="number"
                    value={setup.youth}
                    onChange={(e) => handleNumericChange('youth', e.target.value)}
                  />
                  <p>(max age)</p>
                </div>

              </div>

              <div className='age-main mb-3' >
                <p>Senior</p>

                <div className='max-age'>
                  <input
                    type="number"
                    value={setup.senior}
                    onChange={(e) => handleNumericChange('senior', e.target.value)}
                  />
                  <p>(min age )</p>
                </div>

              </div>
            </>
          )}

          <div className='setup-half'>
            <p>Special Category?</p>
            <Toggle
              checked={isCategory} // Link toggle checked state to `isCategory`
              onChange={(e) => setIsCategory(e.target.checked)} // Update `isCategory` on toggle
            />
          </div>

          {isCategory && (
            <div className='multipliers mb-3'>
                {specialCategoryList.map((category, index) => (
                    <div className="add-category-main mb-3" key={category._id}>
                        {isEditing === index ? (
                            <>
                                <input
                                    type="text"
                                    value={category.title}
                                    onChange={(e) => handleEditInputChange(index, 'title', e.target.value)}
                                    placeholder="Title"
                                />
                                <input
                                    type="text"
                                    value={category.description}
                                    onChange={(e) => handleEditInputChange(index, 'description', e.target.value)}
                                    placeholder="Description"
                                />
                                <Button onClick={() => handleEditCategorySubmit(category._id)}>Save</Button>
                                <Button onClick={() => setIsEditing(null)}>Cancel</Button>
                            </>
                        ) : (
                            <>
                                <input
                                    type="text"
                                    value={category.title}
                                    readOnly
                                />
                                <input
                                    type="text"
                                    value={category.description}
                                    readOnly
                                />
                                <Button style={{ cursor: 'pointer' }} onClick={() => setIsEditing(index)}>Edit</Button>
                                <Button style={{ cursor: 'pointer' }} onClick={() => handleDeleteCategoryRow(category._id)}>Delete</Button>
                            </>
                        )}
                    </div>
                ))}

                <div className="add-category-main mb-3">
                    <input
                        type="text"
                        name="title"
                        value={specialCategory.title}
                        onChange={handleInputChange}
                        placeholder="Title"
                    />
                    <input
                        type="text"
                        name="description"
                        value={specialCategory.description}
                        onChange={handleInputChange}
                        placeholder="Description"
                    />
                    <Button onClick={handleCategorySubmit}>Add</Button>
                </div>
            </div>
        )}
          <div className='button-group'>
            <Button type='button' className='back-create-button' onClick={() => {
              localStorage.setItem("page", "");
              localStorage.setItem("isUpdate", "true");
              navigate('/user-login/create/');
            }}>
              Back
            </Button>
            <Button type='submit' className='next-create-button'>Next</Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SetUp;
